<template>
  <div class="animated fadeIn">
    <b-card style="margin-top: 2rem;">
      <b-row slot="header">
        <b-col cols="12">
          <strong>Reset Password</strong>
        </b-col>
      </b-row>
      <!-- <b-row align-h="end" class="p-2">
        <div>
          
        </div>
      </b-row>-->
      <b-row style="width: 50%;margin: auto;">
        <b-col md="12">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="New Password*:"
            label-for="exampleInput2"
          >
            <b-form-input
              autocomplete="off"
              @change="onNewPassword"
              @keyup="onKeyUp"
              maxlength="50"
              type="password"
              :state="inputValidation"
              v-model="resetForm.newPassword"
              required
              placeholder="New Password"
            />
            <b-form-invalid-feedback :state="inputValidation"
              >password must be min 10 characters long.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="Confirm Password*:"
            label-for="exampleInput2"
          >
            <b-form-input
              autocomplete="off"
              type="password"
              maxlength="50"
              @change="onConfirmPassword"
              :state="validation"
              v-model="resetForm.confirmPassword"
              required
              placeholder="Confirm Password"
            />
            <b-form-invalid-feedback :state="validation"
              >New password and confirmation password do not
              match.</b-form-invalid-feedback
            >
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          align="center"
          style="color: green"
          v-if="errorMesgState"
          >{{ errorMessage }}</b-col
        >
        <b-col md="12" align="right">
          <b-button
            size="md"
            class="btn-success"
            @click="onResetPassword"
            :disabled="validCheck"
          >
            <span v-if="loader">
              <b-spinner small type="grow" label="Loading..." />Loading...
            </span>
            <span v-else>Reset Password</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <div id="message" style="width: 40%; margin: auto; font-weight: 500">
          Note: Password must contain the following
          <div
            id="letter"
            v-bind:style="{ color: inputTypes.guessedName.color }"
          >
            <span v-if="inputTypes.guessedName.value">✔</span
            ><span v-else>✖</span> &nbsp; Should not contain
            <b>guessable part of their names.</b>
          </div>
          <div
            id="letter"
            v-bind:style="{ color: this.inputTypes.string.case.lower.color }"
          >
            <span
              v-if="
                this.inputTypes.string.case.lower.value &&
                  this.inputTypes.string.case.lower.value.length >= 1
              "
              >✔</span
            ><span v-else>✖</span> &nbsp; Atleast <b>1 lowercase</b> character.
          </div>
          <div
            id="capital"
            v-bind:style="{ color: this.inputTypes.string.case.upper.color }"
          >
            <span
              v-if="
                this.inputTypes.string.case.upper.value &&
                  this.inputTypes.string.case.upper.value.length >= 1
              "
              >✔</span
            ><span v-else>✖</span> &nbsp; Atleast <b>1 uppercase</b> character.
          </div>
          <div
            id="capital"
            v-bind:style="{ color: inputTypes.specialChar.color }"
          >
            <span
              v-if="
                inputTypes.specialChar.value &&
                  inputTypes.specialChar.value.length >= 1
              "
              >✔</span
            ><span v-else>✖</span> &nbsp; Atleast <b>1 special</b> character.
          </div>
          <div id="number" v-bind:style="{ color: inputTypes.number.color }">
            <span
              v-if="
                inputTypes.number.value && inputTypes.number.value.length >= 2
              "
              >✔</span
            ><span v-else>✖</span> &nbsp; Atleast
            <b>2 numbers.</b>
          </div>
          <div
            id="length"
            v-bind:style="{ color: inputTypes.validPwdLength.color }"
          >
            <span
              v-if="
                inputTypes.validPwdLength.value.length &&
                  inputTypes.validPwdLength.value.length >= 10
              "
              >✔</span
            ><span v-else>✖</span> &nbsp; Minimum
            <b>10 characters.</b>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      dialogOptions: {
        animation: "bounce",
        backdropClose: true
      },
      activeColor: { color: "red" },
      errorMessage: "",
      show: false,
      errorMesgState: false,
      validCheck: true,
      validation: null,
      inputValidation: null,
      resetForm: {
        newPassword: "",
        confirmPassword: ""
      },
      inputTypes: {
        guessedName: {
          value: false,
          color: "red"
        },
        validPwdLength: {
          value: "",
          color: "red"
        },
        number: {
          value: "",
          color: "red"
        },
        string: {
          case: {
            upper: {
              value: "",
              color: "red"
            },
            lower: {
              value: "",
              color: "red"
            }
          }
        },
        specialChar: {
          value: "",
          color: "red"
        }
      }
    };
  },
  beforeMount() {
    if (!window.navigator.onLine) {
      this.show = true;
    }
  },
  watch: {
    "resetForm.confirmPassword"(val) {
      if (val === this.resetForm.newPassword) {
        this.validCheck = false;
      } else {
        this.validCheck = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      loader: "login/getLoader"
    }),
    class() {
      return darkMode ? "dark-theme" : "light-theme";
    },
    passwordmatch() {
      return this.password.length > 6;
    },
    passwordValidation() {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !!pattern.test(this.username);
    }
  },
  methods: {
    ...mapActions({
      actionResetPassword: "login/resetPassword"
    }),
    onKeyUp(e) {
      var input = "";
      if (e.key === "Backspace" || e.key === "Delete") {
        input = this.inputTypes.validPwdLength.value.split("").pop();
        this.inputTypes.validPwdLength.value = e.target.value;
      } else {
        if (e.key.length <= 1) {
          this.inputTypes.validPwdLength.value += e.key;
          input = e.key;
        }
      }
      if (typeof +input === "number" && !isNaN(+input)) {
        if (e.key === "Backspace") {
          let arr = this.inputTypes.number.value.split("");
          arr.pop();
          let res = arr.join("");
          this.inputTypes.number.value = res;
        } else {
          if (input.length <= 1) {
            this.inputTypes.number.value += input;
          }
        }
      } else {
        var regex = /^[A-Za-z0-9 ]+$/;
        if (e.key === "Backspace") {
          var isValid = regex.test(input);
          if (!isValid) {
            let arr = this.inputTypes.specialChar.value.split("");
            arr.pop();
            let res = arr.join("");
            this.inputTypes.specialChar.value = res;
          } else {
            var isUppercase = /[A-Z]/;
            if (isUppercase.test(input)) {
              let arr = this.inputTypes.string.case.upper.value.split("");
              arr.pop();
              let res = arr.join("");
              this.inputTypes.string.case.upper.value = res;
            } else {
              let arr = this.inputTypes.string.case.lower.value.split("");
              arr.pop();
              let res = arr.join("");
              this.inputTypes.string.case.lower.value = res;
            }
          }
        } else {
          var isValid = regex.test(input);
          if (!isValid) {
            if (input.length <= 1) {
              this.inputTypes.specialChar.value += input;
            }
          } else {
            var isUppercase = /[A-Z]/;
            if (input.length <= 1) {
              if (isUppercase.test(input)) {
                this.inputTypes.string.case.upper.value += input;
              } else {
                this.inputTypes.string.case.lower.value += input;
              }
            }
          }
        }
      }
      if (
        !this.inputTypes.validPwdLength.value.includes(
          window.localStorage.getItem("name")
        )
      ) {
        this.inputTypes.guessedName.value = true;
        this.inputTypes.guessedName.color = "green";
      } else {
        this.inputTypes.guessedName.value = false;
        this.inputTypes.guessedName.color = "red";
      }
      if (this.inputTypes.number.value.length >= 2) {
        this.inputTypes.number.color = "green";
      } else {
        this.inputTypes.number.color = "red";
      }
      if (this.inputTypes.string.case.upper.value.length >= 1) {
        this.inputTypes.string.case.upper.color = "green";
      } else {
        this.inputTypes.string.case.upper.color = "red";
      }
      if (this.inputTypes.string.case.lower.value.length >= 1) {
        this.inputTypes.string.case.lower.color = "green";
      } else {
        this.inputTypes.string.case.lower.color = "red";
      }
      if (this.inputTypes.specialChar.value.length >= 1) {
        this.inputTypes.specialChar.color = "green";
      } else {
        this.inputTypes.specialChar.color = "red";
      }
      if (this.inputTypes.validPwdLength.value.length >= 10) {
        this.inputTypes.validPwdLength.color = "green";
      } else {
        this.inputTypes.validPwdLength.color = "red";
      }
      if (this.inputTypes.validPwdLength.value.length === 0) {
        this.inputTypes = {
          guessedName: {
            value: false,
            color: "red"
          },
          validPwdLength: {
            value: "",
            color: "red"
          },
          number: {
            value: "",
            color: "red"
          },
          string: {
            case: {
              upper: {
                value: "",
                color: "red"
              },
              lower: {
                value: "",
                color: "red"
              }
            }
          },
          specialChar: {
            value: "",
            color: "red"
          }
        };
      }
      // Validate lowercase letters
      //   var lowerCaseLetters = /[a-z]/g;
      // if (myInput.value.match(lowerCaseLetters)) {
      //   letter.classList.remove("invalid");
      //   letter.classList.add("valid");
      // } else {
      //   letter.classList.remove("valid");
      //   letter.classList.add("invalid");
      // }

      // // Validate capital letters
      // var upperCaseLetters = /[A-Z]/g;
      // if (myInput.value.match(upperCaseLetters)) {
      //   capital.classList.remove("invalid");
      //   capital.classList.add("valid");
      // } else {
      //   capital.classList.remove("valid");
      //   capital.classList.add("invalid");
      // }

      // // Validate numbers
      // var numbers = /[0-9]/g;
      // if (myInput.value.match(numbers)) {
      //   number.classList.remove("invalid");
      //   number.classList.add("valid");
      // } else {
      //   number.classList.remove("valid");
      //   number.classList.add("invalid");
      // }

      // // Validate length
      // if (myInput.value.length >= 8) {
      //   length.classList.remove("invalid");
      //   length.classList.add("valid");
      // } else {
      //   length.classList.remove("valid");
      //   length.classList.add("invalid");
      // }
    },
    onConfirmPassword(val) {
      if (val == this.resetForm.newPassword) {
        this.validation = true;
        if (this.resetForm.newPassword == this.resetForm.confirmPassword) {
          this.validCheck = false;
        } else {
          this.validCheck = true;
        }
      } else {
        this.validation = false;
        this.validCheck = true;
      }
    },
    onResetPassword() {
      let payload = {};
      payload.email = window.localStorage.getItem("emailId");
      payload.password = this.resetForm.confirmPassword;
      this.actionResetPassword(payload)
        .then(resp => {
          this.errorMesgState = true;
          this.errorMessage = resp.message;
          this.resetForm.confirmPassword = null;
          this.resetForm.newPassword = null;
          this.validation = null;
          this.inputValidation = null;
          this.validCheck = true;
          if (resp.status) {
            this.$dialog
              .alert(resp.message + " please login again.")
              .then(() => {
                window.localStorage.removeItem("userName");
                window.localStorage.removeItem("token");
                window.localStorage.removeItem("emailId");
                window.localStorage.removeItem("status");
                window.localStorage.removeItem("name");
                window.localStorage.removeItem("siteData");
                window.localStorage.removeItem("loggedin");
                window.localStorage.removeItem("dataUpdateInfo");
                window.localStorage.removeItem('emailHash')
                this.$router.push("/");
              })
              .catch(function() {
                reject();
              });
          }
        })
        .catch(err => {
          this.errorMessage = err.message;
        });

        this.$gtag.event('Clicked_ResetPasswordButton', {
        'event_category': 'Reset Password Screen',
        'event_label': 'Click on Reset Password ',
      })
    },
    onNewPassword(val) {
      this.errorMesgState = false;
      this.resetForm.confirmPassword = "";
      this.validation = null;
      if (this.resetForm.newPasswinputValidationord.length <= 10) {
        this.inputValidation = false;
        this.validCheck = true;
      } else {
        this.inputValidation = true;
        if (
          this.resetForm.newPassword == this.resetForm.confirmPassword &&
          this.inputValidation &&
          this.validation
        ) {
          this.validCheck = false;
        } else {
          this.validCheck = true;
        }
      }
    }
  }
};
</script>

<style>
.card-header {
  padding: 0.75rem 1.25rem;
  color: #ffffff !important;
  margin-bottom: 0;
  background-color: #000000 !important;
  border-bottom: 1px solid #c8ced3;
}
.btn-success {
  background-color: rgb(115, 189, 0) !important;
  border-color: rgb(115, 189, 0) !important;
  color: white;
  box-sizing: border-box;
  border-width: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 6px 12px;
  min-width: 100px;
}
.btn-success:hover {
  background-color: rgb(151, 221, 37) !important;
  border-color: rgb(151, 221, 37) !important;
}
</style>
